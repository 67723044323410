<template>
  <div class="main-container">
    <router-view />
  </div>
</template>

<script>
export default {
  beforeCreate() {
    this.$store.commit("initiliazeDataStore");
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  overflow-y: hidden !important;
}

html, body {
  height: 100%;
}

.main-container {
  height: 100%;
  width: 100%;
  margin: 0;
  text-align: center;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

</style>
